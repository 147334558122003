import React from "react";
import "./style.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const VideoGallery = () => {
  return (
    <div>
      <Header />
      <div className="gallery">
        <div className=" container">
          <div className="gallery-head pb-3">
            <h3>
              <span>previous summit</span> <br /> Videos
            </h3>
          </div>
          <div className="gallery-section">
            <div className="row">
              <div className="col-md-4 mt-4">
                <iframe
                  height={280}
                  className="w-100"
                  src="https://www.youtube.com/embed/O29RmeG0MFY?si=MzE8m9jYYVHWZIir"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/ONFNobSwUz0?si=yKTftQMg4u6pndSR"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/tv4K9e29WaI?si=R6DPLO63pvnnOZd7"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/LrUMCZnbbSc?si=wwqKqZHbPdw1DG09"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/yOVTyBHQI_A?si=B0XELZfaGfLytTSq"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/iFFrKwEfrxg?si=IPKzKlhpA7kQBaqm"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VideoGallery;
