import React from "react";
import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const PhotoGallery = () => {
  return (
    <div>
      <Header />
      <div className="gallery">
        <div className=" container">
          <div className="gallery-head pb-3">
            <h3>
              <span>glimpse of</span> <br /> previous summit
            </h3>
          </div>
          <div className="gallery-section">
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g1.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g2.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g3.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g4.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g6.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g7.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g8.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="gallery-image">
                <img
                  src="./images/g9.jpg"
                  className="w-100"
                  alt="gallery_image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PhotoGallery;
