import krishna from "../../img/advisory/krishna bahadur raut.jpg";
import laxmi from "../../img/advisory/laxmi devi pandey.jpg";
import susil from "../../img/advisory/sushil bhatta.jpg";
import pawan from "../../img/advisory/pawan kumar golyan.jpg";
import hari from "../../img/advisory/haribhakta sharma.jpg";
import bishalsir from "../../img/organizer/bishalsir.jpg";
import deepak from "../../img/speaker/deepak malhotra.jpg";
import prakash from "../../img/advisory/prakash.jpg";

const advData = [
  {
    id: "1",
    image: krishna,
    name: "Mr. Krishna Bahadur Raut",
    position: "Secretary, Ministry of Industry, Commerce & Supplies (GoN)",
  },
  {
    id: "2",
    image: prakash,
    name: "Dr. Prakash Kumar Shreshta",
    position: "Member, NPC",
  },
  {
    id: "3",
    image: laxmi,
    name: "Ms. Laxmi Devi Pandey ",
    position:
      "President, National Asociation of Rural Municipalities in Nepal ",
  },

  {
    id: "4",
    image: susil,
    name: "Mr. Sushil Bhatta ",
    position: "Former CEO, Nepal Investment Board",
  },
  {
    id: "5",
    image: pawan,
    name: "Mr. Pawan Kumar Golyan ",
    position: "Senior Entrepreneur",
  },
  {
    id: "6",
    image: hari,
    name: "Mr. Hari bhakta Sharma ",
    position: "Former President, CIN",
  },
  {
    id: "7",
    image: deepak,
    name: "Mr. Deepak Malhotra",
    position: "Chairman, IMS Group",
  },
  {
    id: "7",
    image: bishalsir,
    name: "Mr. Bishal Gaire",
    position: "Chief Executive Chairperson, NBI",
  },
];

export { advData };
