import bishnu from "../../img/distinguished/Bishnu_FM.jpg";
import arju from "../../img/distinguished/arju deuba.jpg";
import balaram from "../../img/distinguished/balaram adhikari.jpg";
import damodar from "../../img/distinguished/damodar.jpg";
import kpoli from "../../img/distinguished/kpoli1.jpg";
import prachanda from "../../img/distinguished/prachanda.jpg";
import ramnath from "../../img/distinguished/ramnath.jpg";
import prithivi from "../../img/distinguished/prithivi subba.jpg";

const distData = [
  {
    id: "1",
    image: kpoli,
    name: "Rt. Hon. K.P Sharma Oli",
    position: "Prime Minister of Nepal (GoN)",
    type: "Inaguration Ceremony (Chief Guest) ",
  },
  {
    id: "2",
    image: prachanda,
    name: "Hon. Pushpa Kamal Dahal",
    position: "Former Prime Minister of Nepal (GoN)",
    type: "Closing Ceremony (Chief Guest)",
  },
  {
    id: "3",
    image: bishnu,
    name: "Hon. Bishnu Prashad Paudel",
    position: "Deputy prime Minister and Minister for Finance (GoN)",
  },

  {
    id: "4",
    image: prithivi,
    name: "Hon. Prithivi Subba Gurung ",
    position: "Minister for Communication and Information Technology (GoN)",
  },
  {
    id: "5",
    image: arju,
    name: "Hon. Arzu Rana Deuba ",
    position: "Minister for Foreign Affairs  (GoN)",
  },
  {
    id: "6",
    image: balaram,
    name: "Hon. Balaram Adhikari",
    position:
      "Minister for Land Management, Cooperatives and Poverty Alleviation (GoN)",
  },
  {
    id: "7",
    image: damodar,
    name: "Hon. Damodar Bhandari ",
    position: "Minister for Industry, Commerce & Supplies (GoN)",
  },
  {
    id: "8",
    image: ramnath,
    name: "Hon. Ramnath Adhikari ",
    position: "Minister for Agriculture and Livestock Department (GoN)",
  },
];

export { distData };
