import hikmat from "../../img/speaker/hikmat karki.jpg";
import satish from "../../img/speaker/satish kumar singh.jpg";
import bahadur from "../../img/speaker/bahadur singh.jpg";
import surendra from "../../img/speaker/surendra raj pandey.jpg";
import chet from "../../img/speaker/chet narayan acharya.jpg";
import yamlal from "../../img/speaker/yamlal.jpg";
import kamal from "../../img/speaker/kamal bahadur.jpg";
import binod from "../../img/speaker/binod chaudhari.jpg";
import shiva from "../../img/speaker/shiva raj adhikari.jpg";
import maha from "../../img/speaker/maha prasad adhikari.jpg";
import yubraj from "../../img/speaker/yubraj.jpg";
import chandra from "../../img/speaker/chandra dhakal4.jpg";
import rajesh from "../../img/speaker/rajesh kumar agrawal.jpg";
import kamlesh from "../../img/speaker/kamlesh.jpg";
import hari from "../../img/speaker/haribhakta sharma.jpg";
import deepak from "../../img/speaker/deepak malhotra.jpg";
const spkData = [
  {
    id: "1",
    image: hikmat,
    name: "Hon'ble Hikmat Karki",
    position: "Chief Minister, Koshi Province (GoN)",
  },
  {
    id: "2",
    image: satish,
    name: "Hon'ble Satish Kumar Singh",
    position: "Chief Minister, Madhesh Province (GoN)",
  },
  {
    id: "3",
    image: bahadur,
    name: "Hon'ble Bahadur Singh Lama",
    position: "Chief Minister, Bagmati Province (GoN)",
  },
  {
    id: "4",
    image: surendra,
    name: "Hon'ble Surendra Raj Pandey",
    position: "Chief Minister, Gandaki Provence (GoN)",
  },
  {
    id: "5",
    image: chet,
    name: "Hon'ble Chet Narayan Acharya",
    position: "Chief Minister, Lumbini Provence (GoN)",
  },
  {
    id: "6",
    image: yamlal,
    name: "Hon'ble Yam Lal Kandel",
    position: "Chief Minister, Karnali Provence (GoN)",
  },
  {
    id: "7",
    image: kamal,
    name: "Hon'ble Kamal Bahadur Shah",
    position: "Chief Minister, Sudurpashchim Provence (GoN)",
  },
  {
    id: "8",
    image: binod,
    name: "Hon'ble Binod Chaudhari",
    position: "Billionaire Entrepreneur/Member of parliament",
  },
  {
    id: "9",
    image: shiva,
    name: "Prof.Dr. Shiva Raj Adhikari",
    position: "Vice-Chairman, National Planning Commission(NPC)",
  },
  {
    id: "10",
    image: maha,
    name: "Mr. Maha Prasad Adhikari",
    position: "Governor, NRB",
  },
  {
    id: "11",
    image: yubraj,
    name: "Mr. Yuba Raj Khatiwada",
    position: "Economic Advisory of Prime Miniser of Nepal",
  },
  {
    id: "12",
    image: chandra,
    name: "Mr. Chandra Prasad Dhakal",
    position: "President of FNCCI",
  },
  {
    id: "13",
    image: hari,
    name: "Mr. Hari bhakta Sharma ",
    position: "Former President, CNI",
  },
  {
    id: "14",
    image: rajesh,
    name: "Mr. Rajesh Kumar Agrawal",
    position: "President, CNI",
  },
  {
    id: "15",
    image: kamlesh,
    name: "Mr. Kamlesh Kumar Agrawal",
    position: "President, NCC",
  },
  {
    id: "16",
    image: deepak,
    name: "Mr. Deepak Malotahra ",
    position: "Chairman, IMS Group",
  },
];

export { spkData };
