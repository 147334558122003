import React from "react";
import { FacebookProvider, Page } from "react-facebook";
import "./style.css";
import info from "../../img/chandra.jpg";
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";
import { BsTelephoneFill, BsGlobe } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="pt-5  footers mt-5">
      <footer className="footer pb-5 container">
        <div className="row w-100">
          <div className="col-lg-4 col-md-6 footer-mbl">
            <h3 className="title">Contact Details</h3>
            <div className="center">
              <div className="contact_details pt-4">
                <h5>
                  <FaMapMarkerAlt /> Dillibazar, Kathmandu,Nepal
                </h5>
                <h5>
                  <BsTelephoneFill /> 9841049104
                </h5>
                <h5>
                  <FaEnvelope /> nepalbusinesssummit@gmail.com
                </h5>
                <h5>
                  <BsGlobe /> www.nepalbusinesssummit.com{" "}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 mt-md-5">
            <div className="mt-md-5">
              <FacebookProvider appId="144389435029293">
                <Page
                  href="https://www.facebook.com/nepalbusinesssummit2022"
                  tabs="timeline"
                  height="220px"
                  className="w-100"
                />
              </FacebookProvider>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="title founder">Program Officer</h3>
            <div
              className="text-center"
              style={{
                marginTop: 40,
                backgroundColor: "#fff",
                width: "100%",
                height: 250,
              }}
            >
              <div style={{ paddingTop: 20 }}>
                <img src={info} width={120} alt="..." height={140} />
                <h5
                  style={{
                    color: "#284e9c",
                    paddingTop: 10,
                    marginBottom: 2,
                  }}
                >
                  Mr. Chandra Bhakta Adhikari
                </h5>
                <h5 style={{ marginBottom: 3, fontWeight: 400 }}>
                  Program officer
                </h5>
                <h5 style={{ marginBottom: 2, fontWeight: 400, fontSize: 18 }}>
                  <BsTelephoneFill /> 9841049104
                </h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ backgroundColor: "#102349", padding: "0% 12%" }}>
        <h5
          style={{
            color: "#fff",
            fontWeight: 400,
            fontSize: "18px",
            padding: "10px 0px",
            marginBottom: "0px",
          }}
        >
          Nepal Business Summit &copy; 2025.
        </h5>
      </div>
    </div>
  );
};

export default Footer;
