import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";

const Souvenir = () => {
  return (
    <>
      <Header />
      <div>
        <div className="about-head p-3 p-md-5">
          <div className="container">
            <center>
              <h2>Nepal Business Summit 2025 Souvenir Proposal</h2>
              <p className="text-white">
                Economy for a sustainable and resilient future.
              </p>
            </center>
          </div>
        </div>
        <div className="container about pt-2 pt-md-5 pb-2 pb-md-5 rts">
          <section class="focus-areas">
            <h3 className="rate">Key Focus Areas</h3>
            <ul>
              <li>
                <strong>Agro-Business:</strong> Highlighting the potential of
                agriculture-based industries, showcasing innovations in farming
                techniques, sustainable practices, and agro-technology to
                enhance productivity and ensure food security.
              </li>
              <li>
                <strong>Digitalization and Technology:</strong> Exploring the
                transformative impact of digital tools and technologies across
                various industries, fostering efficiency, connectivity, and
                innovation in business processes.
              </li>
              <li>
                <strong>Tourism:</strong> Showcasing Nepal’s unique cultural and
                natural assets while exploring strategies for sustainable
                tourism development, diversification, and global market
                positioning.
              </li>
              <li>
                <strong>SME Business:</strong> Empowering small and medium
                enterprises by addressing challenges, providing innovative
                solutions, and fostering growth to strengthen the backbone of
                the economy.
              </li>
              <li>
                <strong>Potential Investment Sectors:</strong> Identifying and
                promoting high-potential sectors for investment, facilitating
                collaborations, and presenting opportunities for both domestic
                and international investors.
              </li>
              <li>
                <strong>Startup and Innovation:</strong> Encouraging
                entrepreneurial ventures by highlighting groundbreaking ideas,
                addressing challenges, and fostering a culture of innovation to
                build a vibrant startup ecosystem.
              </li>
              <li>
                <strong>Policy and Governance:</strong> Exploring strategies for
                sustainable economic growth.
              </li>
              <li>
                <strong>Sustainability and CSR:</strong> Promoting responsible
                and ethical business practices.
              </li>
              <li>
                <strong>Global Trends and Opportunities:</strong> Identifying
                emerging global business trends and how Nepal can leverage them.
              </li>
              <li>
                <strong>Capacity Building:</strong> Focusing on skills
                development and leadership for a competitive workforce.
              </li>
            </ul>
          </section>
          <section class="benefits">
            <h3 className="rate">Benefits of Advertising</h3>
            <ul>
              <li>
                <strong>Enhanced Visibility:</strong> Your brand will be
                prominently showcased to key stakeholders and decision-makers.
              </li>
              <li>
                <strong>Targeted Outreach:</strong> Connect with organizations
                and individuals driving Nepal’s business landscape.
              </li>
              <li>
                <strong>Corporate Social Responsibility (CSR):</strong>{" "}
                Highlight your commitment to fostering innovation and growth.
              </li>
              <li>
                <strong>Long-term Impact:</strong> The souvenir will serve as a
                valuable reference, ensuring prolonged visibility for your
                brand.
              </li>
              <li>
                <strong>Digital Reach:</strong> Global online distribution
                amplifies your advertisement’s impact.
              </li>
            </ul>
          </section>
          <h3 className="rate">Rate Card</h3>
          <p className="rate-p">
            {" "}
            We believe the souvenir for Nepal Business Summit 2025 will outline
            Nepal's economic landscape and future roadmap. 10,000 printed copies
            (125 pages, A4 size) will be distributed to government bodies,
            private sector agencies, embassies, and international partners.
            Additionally, it will reach 50,000 target groups via email.
          </p>
          <div className="souv">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>Add Type</th>
                    <th>Price (NPR)</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Inside Front Cover (Full)</td>
                    <td>Rs. 250,000.00/-</td>
                    <td>Premium Placement, Full-color</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Inside Back Cover (Full)</td>
                    <td>Rs. 250,000.00/-</td>
                    <td>Premium Placement, Full-color</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Inner Page (Full)</td>
                    <td>Rs. 200,000.00/-</td>
                    <td>Multi-color</td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>Inner Page (Half)</td>
                    <td>Rs. 150,000.00/-</td>
                    <td>Multi-color</td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>Quarter Page</td>
                    <td>Rs. 100,000.00/-</td>
                    <td>Multi-color</td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>Message of Best Wishes</td>
                    <td>Rs. 50,000.00/-</td>
                    <td>Text-only Message</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="container-contact">
            <div className="contact-content">
              <div className="left-side">
                <h3>More Info</h3>
                <div className="address details">
                  <i className="fas fa-map-marker-alt" />
                  <div className="topic">Event Venue</div>
                  <div className="text-one">Kathmandu, Nepal</div>
                </div>
                <div className="phone details">
                  <i className="fas fa-phone-alt" />
                  <div className="topic">Contact</div>
                  <div className="text-one">9841049104</div>
                </div>
                <div className="email details">
                  <i className="fas fa-envelope" />
                  <div className="topic">Email</div>
                  <div className="text-one">nepalbusinesssummit@gmail.com</div>
                </div>
              </div>
              <div className="right-side">
                <a
                  href="https://forms.gle/D8K5Khtnw6CRNBwx5"
                  target="__blank"
                  className="form_btn"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Souvenir;
