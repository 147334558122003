import React from "react";
import Footer from "../components/footer/Footer";
import Headers from "../components/header/Headers";
import "./style.css";

const Register = () => {
  return (
    <>
      <Headers />
      <div className="registers">
        <div className="form_section">
          {/* <div className="form-image">
            <img src="./images/logo.png" alt="" />
          </div> */}
          <div className="reg_title">
            <h2>DELEGATES REGISTRATION FORM</h2>
            {/* <p>Note: Opening after 25th August 2023</p> */}
          </div>
          <div className="fee_table ps-2 pe-2 ps-lg-5 pe-lg-5">
            <table>
              <thead>
                <tr>
                  <th>Registration Details</th>
                  <th>Fees</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Corporate Registrations (Institution)</td>
                  <td>Rs. 10000.00/- (Exclusive of vat)</td>
                </tr>
                <tr>
                  <td>Entrepreneurs (Kathmandu Valley)</td>
                  <td>Rs. 10000.00/- </td>
                </tr>
                <tr>
                  <td>
                    Entrepreneurs from Provinces (Outside Kathmandu Valley)
                  </td>
                  <td>Rs. 8000.00/- </td>
                </tr>
                <tr>
                  <td>Foreign Entepreneurs </td>
                  <td>USD. 200.00/- (Exchange reate of payment day)</td>
                </tr>
                <tr>
                  <td>Startups </td>
                  <td>Rs.6000.00/- </td>
                </tr>
                <tr>
                  <td>Students </td>
                  <td>Rs. 5000.00/- (Student card mandatory)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="dtv">
            <div className="date">
              <h5>DATE</h5>
              <p>28th February, 2025</p>
            </div>
            <div className="date">
              <h5>START TIME</h5>
              <p>8AM-8PM</p>
            </div>
            <div className="date">
              <h5>VENUE</h5>
              <p>Kathmandu, Nepal</p>
            </div>
          </div>
          <div className="form">
            <form>
              <div className="notes">
                <p className="note">
                  *You are required to send a screenshot of the statement as a
                  proof to the email nepalbusinesssummit@gmail.com or what&#39;s
                  app at +977-9841049104.
                  <p></p>
                </p>
              </div>
              {/* <div className="form_label agrees">
                <input type="checkbox" id="agree" />
                <label htmlFor="agree" className="agree ps-2">
                  I agree to participate in all sessions of the NYES 2023.
                </label>
              </div> */}
              <div className="reg-button mt-5 mb-4">
                <a
                  href="https://forms.gle/oFTU6mUmn2eo1kjx6"
                  target="__blank"
                  className="form_btn"
                >
                  Go For Form Submission
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
