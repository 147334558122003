import prakash from "../../img/organizer/prakash.jpg";
import pawan from "../../img/organizer/pawan kumar golyan.jpg";
import upendra from "../../img/organizer/upendra.png";
import bishalsir from "../../img/organizer/bishalsir.jpg";
import roshan from "../../img/organizer/roshan koirala.jpg";
import rajesh from "../../img/organizer/rajesh upadhaya.jpg";
import kush from "../../img/organizer/kush prasad mally.jpg";
import bharat from "../../img/organizer/bharat dhakal.jpg";
import naresh from "../../img/organizer/naresh prasad sharma.jpg";
import dharma from "../../img/organizer/dharma raj regmi.jpg";
import semant from "../../img/organizer/semanta.jpg";
import pawanp from "../../img/organizer/pawan pyakhurel.jpg";
import akriti from "../../img/organizer/akriti pandey.jpg";
import chandra from "../../img/organizer/chandra bhakta adhikari.jpg";
import anil from "../../img/organizer/anil shrestha.jpg";
import rohan from "../../img/organizer/rohan.jpg";
import saphal from "../../img/organizer/saphal.jpg";
const ocData = [
  {
    id: "1",
    image: bishalsir,
    name: "Mr. Bishal Gaire",
    position: "Summit Coordinator ",
  },
  {
    id: "2",
    image: dharma,
    name: "Mr. Dharmaraj Reghmi",
    position: "Member",
  },
  {
    id: "3",
    image: naresh,
    name: "Mr. Naresh Prasad Shrestha",
    position: "Member",
  },
  {
    id: "4",
    image: semant,
    name: "Mr. Semantamani Kharal",
    position: "Member",
  },
  {
    id: "5",
    image: pawanp,
    name: "Mr. Pawan Pyakurel",
    position: "Member",
  },
  {
    id: "6",
    image: akriti,
    name: "Ms. Akariti Pandey ",
    position: "Member",
  },
  {
    id: "7",
    image: chandra,
    name: "Mr. Chandra Bhakta Adhikari ",
    position: "Member",
  },
  {
    id: "8",
    image: anil,
    name: "Mr. Anil Shrestha ",
    position: "Member",
  },
  {
    id: "9",
    image: rohan,
    name: "Mr. Rohan Maharjan",
    position: "Member",
  },
  {
    id: "10",
    image: saphal,
    name: "Mr. Saphal Subedi",
    position: "Member",
  },
];

export { ocData };
